/* eslint-disable react/static-property-placement */
import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import withContentCards from 'components/withContentCards';
import classNames from 'classnames';
import { trackEvent } from '@surfline/web-common';
import { useTreatments } from 'utils/treatments';
import { SL_WEB_FREEMIUM_V3_FORECAST_DAYS } from 'common/treatments';
import { CLICKED_SUBSCRIBE_CTA } from '../../common/constants';

import styles from './ForecastGraphsCTADaySelector.module.scss';

interface BrazeCard {
  name?: string;
  extras: {
    box__background: string;
    box__body: string;
    box__button: string;
    box__link: string;
    box__title: string;
  } | null;
}

type Props = {
  card?: BrazeCard;
  segmentProperties: {
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
  isDesktop: boolean;
};

const ForecastGraphsCTADaySelector: React.FC<Props> = ({ card, segmentProperties, isDesktop }) => {
  const treatments = useTreatments();
  const freemiumTreatmentEnabled = treatments[SL_WEB_FREEMIUM_V3_FORECAST_DAYS] === 'on';

  return (
    <Box
      className={classNames({
        [styles.ctaWrapper]: true,
        [styles.ctaWrapperTenDay]: freemiumTreatmentEnabled,
      })}
      data-testid="day-selector-paywall"
      sx={{ backgroundColor: card?.extras?.box__background }}
    >
      <Box className={styles.ctaTextWrapper}>
        <Typography variant="callout1" className={styles.ctaTitle}>
          {card?.extras?.box__title || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
        </Typography>
        <Typography
          variant="caption2"
          component="p"
          display="block"
          lineHeight={1.3}
          className={styles.ctaBody}
        >
          {card?.extras?.box__body || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
        </Typography>
      </Box>
      <Button
        aria-label="premium upgrade link"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        href={card?.extras?.box__link || '/upgrade'}
        onClick={() => {
          trackEvent(CLICKED_SUBSCRIBE_CTA, {
            ...segmentProperties,
          });
        }}
        size={isDesktop ? 'medium' : 'small'}
        className={styles.ctaButton}
        variant="primary"
      >
        {card?.extras?.box__button}
      </Button>
    </Box>
  );
};

export default withContentCards(ForecastGraphsCTADaySelector as React.ComponentType<{}>);
