import React from 'react';
import { conditionClassModifier, conditionEnumToText } from '@surfline/web-common';
import { Typography, Stack, Box } from '@mui/material';
import classNames from 'classnames/bind';

import { useMaxWidthTabletLarge } from 'hooks/useMediaQueries';
import formatWaveHeight from 'utils/formatWaveHeight';
import type { ForecastWaveHeight } from '../../types/spot';
import type { Units } from '../../types/units';
import getTooltipTextStyles from '../../utils/getTooltipTextStyles';

import styles from './CurrentWaveHeightAndRating.module.scss';

const cx = classNames.bind(styles);

export enum Size {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
}

const getConditionsClassName = (
  className: string,
  conditions: string,
  type: 'background' | 'color',
  alwaysDisplaySmall: boolean,
  isTooltipMode: boolean = false,
) =>
  cx({
    [className]: true,
    [styles.conditionsBlockSmall]: !!alwaysDisplaySmall && className !== 'conditionsText',
    [`sl-conditions-color--${conditionClassModifier(conditions)}-${type}`]: true,
    [styles.isTooltip]: isTooltipMode,
  });

interface Props {
  alwaysDisplaySmall?: boolean;
  conditions: string | null;
  isFlat: boolean;
  isTooltipMode?: boolean;
  size?: Size;
  units: Units;
  waveHeight: ForecastWaveHeight;
}

const CurrentWaveHeightAndRating: React.FC<Props> = ({
  alwaysDisplaySmall,
  conditions,
  isFlat,
  isTooltipMode = false,
  size,
  units,
  waveHeight,
}) => {
  const isMobileView = useMaxWidthTabletLarge();

  const waveHeightVariantSmall = isMobileView ? 'title2' : 'title1';
  const waveHeightVariantDefault = isMobileView ? 'h2' : 'h1';
  const waveHeightVariant = size === Size.SMALL ? waveHeightVariantSmall : waveHeightVariantDefault;

  const unitsVariantSmall = isMobileView ? 'h4' : 'h3';
  const unitsVariantDefault = isMobileView ? 'h4' : 'h3';
  const unitsVariant = size === Size.SMALL ? unitsVariantSmall : unitsVariantDefault;

  const conditionsVariantSmall = isMobileView || alwaysDisplaySmall ? 'callout1' : 'headline';
  const conditionsVariantDefault = isMobileView || alwaysDisplaySmall ? 'overline2' : 'h3';
  const conditionsVariant =
    size === Size.SMALL || alwaysDisplaySmall ? conditionsVariantSmall : conditionsVariantDefault;

  return (
    <Stack
      direction="row"
      className="current-wave-height-and-rating"
      data-testid="current-wave-height-and-rating"
    >
      {conditions && !isTooltipMode && (
        <Box
          className={getConditionsClassName(
            'conditionsBlock',
            conditions,
            'background',
            !!alwaysDisplaySmall,
            isTooltipMode,
          )}
        />
      )}
      <Stack direction="column">
        <Stack direction="row" alignItems="baseline" className={styles.waveHeight}>
          {isFlat ? (
            <Typography
              whiteSpace="nowrap"
              variant={
                isTooltipMode || !!alwaysDisplaySmall
                  ? getTooltipTextStyles(
                      isMobileView && !alwaysDisplaySmall,
                      !!isTooltipMode || !!alwaysDisplaySmall,
                      'title1',
                      'title3',
                      'h2',
                      'h1',
                    )
                  : waveHeightVariant
              }
              component="p"
              color="text.brandSecondary"
            >
              Flat
            </Typography>
          ) : (
            <Typography component="p">
              <Typography
                whiteSpace="nowrap"
                variant={
                  isTooltipMode || !!alwaysDisplaySmall
                    ? getTooltipTextStyles(
                        isMobileView && !alwaysDisplaySmall,
                        !!isTooltipMode || !!alwaysDisplaySmall,
                        'title1',
                        'title3',
                        'h2',
                        'h1',
                      )
                    : waveHeightVariant
                }
                component="span"
                textTransform="lowercase"
                color="text.brandSecondary"
              >
                {waveHeight.min}-{waveHeight.max}
              </Typography>
              <Typography
                whiteSpace="nowrap"
                variant={
                  isTooltipMode || !!alwaysDisplaySmall
                    ? getTooltipTextStyles(
                        isMobileView && !alwaysDisplaySmall,
                        !!isTooltipMode || !!alwaysDisplaySmall,
                        'title3',
                        'callout1',
                        'h4',
                        'h3',
                      )
                    : unitsVariant
                }
                component="span"
                textTransform="lowercase"
                color="text.brandSecondary"
              >
                {formatWaveHeight(units.waveHeight)}
              </Typography>
              {waveHeight.plus && (
                <Typography
                  whiteSpace="nowrap"
                  variant={
                    isTooltipMode || !!alwaysDisplaySmall
                      ? getTooltipTextStyles(
                          isMobileView && !alwaysDisplaySmall,
                          !!isTooltipMode || !!alwaysDisplaySmall,
                          'title3',
                          'callout1',
                          'h2',
                          'h1',
                        )
                      : waveHeightVariant
                  }
                  component="span"
                  textTransform="lowercase"
                  color="text.brandSecondary"
                >
                  +
                </Typography>
              )}
            </Typography>
          )}
        </Stack>
        {conditions && conditions !== 'NONE' && (
          <Typography
            whiteSpace="nowrap"
            className={getConditionsClassName(
              'conditionsText',
              conditions,
              'color',
              !!alwaysDisplaySmall,
              isTooltipMode,
            )}
            variant={conditionsVariant}
            component="p"
            data-testid="conditions-text"
          >
            {conditionEnumToText(conditions, ' ')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default CurrentWaveHeightAndRating;
